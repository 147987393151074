import React, { FC, useState } from 'react';

export interface FAQCardProps {
  title: string;
  id: string;
  sections: {
    title: string;
    body: JSX.Element;
  }[];
}

export const FAQCard: FC<FAQCardProps> = props => {
  const [openMap, setOpenMap] = useState({});

  return (
    <div
      id={props.id}
      className={
        'flex flex-col ts:w-full tw:w-card-smaller w-card-default px-20 py-12 ts:p-8 tw:p-12 shadow-faq-card-default mb-8'
      }
    >
      <div
        className={`text-left font-roboto`}
        style={{
          color: '#1D2251',
          fontSize: '24px',
          fontWeight: 400,
          lineHeight: '29px',
        }}
      >
        {props.title}
      </div>
      {props.sections.map((section, index) => {
        const isOpen = !!openMap[index];
        return (
          <div
            style={{
              marginTop: '15px',
            }}
          >
            <div
              className={'cursor-pointer'}
              style={{
                color: 'var(--color-accent-dark)',
                letterSpacing: '0.4px',
                lineHeight: '19px',
                fontWeight: 400,
                userSelect: 'none',
              }}
              onClick={() => {
                setOpenMap({
                  ...openMap,
                  [index]: !openMap[index],
                });
              }}
            >
              <i
                className={`ri-arrow-${isOpen ? 'up' : 'right'}-s-line`}
                style={{
                  fontSize: '16px',
                  verticalAlign: 'middle',
                }}
              ></i>{' '}
              {section.title}
            </div>
            {openMap[index] && (
              <div
                className={'text-sm'}
                style={{
                  marginTop: '15px',
                  marginLeft: '4px',
                  letterSpacing: '0.4px',
                  lineHeight: '24px',
                }}
              >
                {section.body}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
