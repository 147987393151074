import { FAQCard } from 'components/FAQCard/FAQCard';
import React from 'react';

export const makeGeneTestDescription = (title: string, description: string, withBottomMargin: boolean = true) => {
  return (
    <div className={`${withBottomMargin ? 'mb-6' : ''}`}>
      <div
        style={{
          color: 'var(--color-primary)',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        {title}
      </div>
      <div>{description}</div>
    </div>
  );
};

const PaymentsFAQ = (
  <FAQCard
    id="faq-payments"
    title={'Payments'}
    sections={[
      {
        title: 'How do I make payments for my consultation?',
        body: (
          <>
            <p>
              This is a cash practice and you are expected to settle your account prior to leaving on the day of your
              consultation.{' '}
            </p>
            <p className={'mt-8'}>
              Payments can be made via <span className={'color-primary font-semibold'}>CARD</span>,{' '}
              <span className={'color-primary font-semibold'}>CASH</span> or direct{' '}
              <span className={'color-primary font-semibold'}>EFT*</span> at the practice.
            </p>
            <p className={'mt-8 italic'}>
              * I use an easy secure 3D payment system, <span className={'color-primary font-semibold'}>SAGE PAY</span>.
              It sends you an OTP to allow payment on your mobile phone. An electronic receipt is immediately created
              once payment is cleared and sent to your inbox. It’s as easy as that. Just remember to charge your phone.
            </p>
          </>
        ),
      },
      {
        title: 'Can I claim from my medical aid?',
        body: (
          <>
            <p>
              Ina is contracted <span className={'color-primary font-semibold'}>OUT</span> of medical aids (you as the
              patient need to pay for the services in cash and then submit a claim to the medical aid for
              reimbursement).
            </p>
            <p className={'mt-8'}>
              The medical aid will not cover Genetic tests, Food sensitivity tests, Hunter Virtual 3D Scans or any
              supplements - they are for your own account.
            </p>
            <p className={'mt-8'}>
              You can confirm with your medical aid which portion of the consultations they will cover before you book.
              The ICD 10 code I use is Z71.3 The procedure codes that may be used (time-dependant) are as follow:
            </p>
            <div
              className={'grid mt-8'}
              style={{
                gridTemplateColumns: 'auto auto',
                columnGap: '16px',
              }}
            >
              <span className={'color-primary font-semibold'}>Code</span>
              <span className={'color-primary font-semibold'}>Timebox</span>
              <span>84202</span>
              <span>Nutritional assessment, counselling and/or treatment 21-30 minutes</span>
              <span>84203</span>
              <span>Nutritional assessment, counselling and/or treatment 31-40 minutes</span>
              <span>84205</span>
              <span>Nutritional assessment, counselling and/or treatment 41-60 minutes</span>
              <span>84206</span>
              <span>Nutritional assessment, counselling and/or treatment 61-80 minutes</span>
              <span>84208</span>
              <span>Nutritional assessment, counselling and/or treatment 81-90 minutes</span>
            </div>
          </>
        ),
      },
    ]}
  />
);

const ServicesFAQ = (
  <FAQCard
    id="faq-services"
    title={'Services'}
    sections={[
      {
        title: 'What is a Hunter Virtual 3D scan?',
        body: (
          <>
            <p>
              This is a great way to monitor any possible underlying conditions and how the body responds to treatment
              modalities, all in real time. The Hunter Virtual 3D scan is a non-invasive scan that can be done in
              conjunction with any one-on-one or couples consultations. It can also be booked as a standalone
              consultation. The Hunter Virtual 3D scan is charged for separately to the consultation it is booked in
              conjunction with.
            </p>
          </>
        ),
      },
      {
        title: 'Will Ina give me a meal plan?',
        body: (
          <>
            <p>
              During any of your appointments you can ask Ina to develop you a meal plan. Meal plans are charged at R
              490.00 per person.
            </p>
          </>
        ),
      },
      {
        title: 'I would like to do a food sensitivity test. How does it work?',
        body: (
          <>
            <p>
              An easy, painless finger prick test is done and analyzed to identify food sensitivities (intolerances). By
              doing a food sensitivity test Ina can identify foods that cause sensitivities and excluded them from your
              diet. Supplements to improve immunity and lower histamine reactions will also be suggested. Foods with no
              reaction can be eaten during this period.
            </p>
          </>
        ),
      },
      {
        title: 'What is the difference between an allergy and food sensitivity?',
        body: (
          <>
            <p className={'color-primary font-semibold'}>Food Allergies</p>
            <p>
              Food allergies generally happen quickly and can be life-threatening. The symptoms are immediate and can
              happen within two hours after contact to the food. Common symptoms include hives, severe itching, swelling
              of the lips, face, throat, and even asthma attacks.
            </p>
            <p className={'mt-8 color-primary font-semibold'}>Food Sensitivity</p>
            <p>
              Food sensitivities impact your quality of life over a period. A food sensitivity is a toxic reaction to
              food over time. The symptoms of food sensitivities are often delayed and subtle at first surfacing within
              a few hours after consumption. Reactions can last up to four days.
            </p>
          </>
        ),
      },
    ]}
  />
);

const BookingsFAQ = (
  <FAQCard
    id="faq-bookings"
    title={'Bookings & Consultations'}
    sections={[
      {
        title: "I'm a first time customer, how do I book?",
        body: (
          <>
            <p>
              The best way to book is online. Click{' '}
              <a href="https://bookings.robotdesk.co.za/inanortje/ina" target="_blank" className={'clickable-word'}>
                {' '}
                HERE
              </a>
              . to make a booking
            </p>
          </>
        ),
      },
      {
        title: 'Do I need to fill out a questionnaire?',
        body: (
          <>
            <p>
              If you are a new client and you book an appointment, a link to a questionnaire will be emailed to you for
              completion. You can complete the questionnaire on your computer or mobile device. Once complete, hit
              <span className={'font-semibold bold'}> “Submit”</span> and watch the magic happen. 💫
            </p>
            <p className={'mt-4'}>
              Please note that questionnaires need to be completed at least 24 hours before your appointment.
            </p>
          </>
        ),
      },
      {
        title: 'Do you offer virtual consultations?',
        body: (
          <>
            <p>
              Yes, you can opt to book any* of your consultations as a virtual consultation. Be sure that you have the
              Zoom app installed on your computer 💻 or mobile device 📱. A Zoom link will be e-mailed to you once Ina
              confirms your virtual booking. Follow that link on the day of your appointment to start your consultation.
            </p>

            <p className={'mt-4 italic'}>
              *Unfortunately the Hunter Virtual 3D scan, Body Composition Assessment & Vitality Assessment will not be
              available virtually.
            </p>

            <p className={'mt-4'}>
              You can download and install Zoom{' '}
              <a href="https://zoom.us/download" target="_blank" className={'clickable-word'}>
                {' '}
                HERE
              </a>
            </p>
          </>
        ),
      },
      {
        title: 'I have a child, can I bring them along to my appointment?',
        body: (
          <>
            <p>
              We love children, but due to the nature of the consultation it is advised that they don't come along. If
              you can not make any alternative arrangement please contact Ina in advance to notify her that your child
              will be joining.
            </p>
            <p className={'mt-4'}>If your child is the patient please be sure to accompany them to the appointment.</p>
          </>
        ),
      },
      {
        title: 'What does a First Consultation consist of?',
        body: (
          <>
            <p className={'mt-4 color-primary font-semibold'}>Anthropometric Assessment</p>
            <p>Weight, height, waist circumference, body fat, muscle mass, and visceral fat measurements.</p>
            <p className={'mt-4 color-primary font-semibold'}>Biochemical Assessment</p>
            <p>
              Ina is registered with Lancet, Pathcare and Ampath therefore if you do need blood tests done she can
              recommend which ones.
            </p>
            <p className={'mt-4 color-primary font-semibold'}>Clinical Assessment</p>
            <p>This is a discussion regarding any symptoms that you are experiencing.</p>
            <p className={'mt-4 color-primary font-semibold'}>Medical Assessment</p>
            <p>
              Here your family medical history, medications you're using, and whether you should go for genetic or food
              sensitivity testing are discussed.
            </p>
            <p className={'mt-4 color-primary font-semibold'}>Dietary Assessment</p>
            <p>Your previous dietary intake is assessed, then suggested changes and goals are formulated.</p>
            <p className={'mt-4 color-primary font-semibold'}>Planning Your Journey</p>
            <p className={'mb-4'}>
              You'll be given a platform to understand vital concepts of nutrition, diseases and symptoms, as well as
              how these concepts are interlinked and what you can do to improve and reach your health goal.
            </p>
          </>
        ),
      },
      {
        title: 'What does a Second Consultation consist of?',
        body: (
          <>
            <p className={'mt-4 color-primary font-semibold'}>Biochemical & Clinical Feedback</p>
            <p>
              If you have had any blood tests done, Ina will provide you with a comprehensive discussion and
              explanation.
            </p>
            <p className={'mt-4 color-primary font-semibold'}>Receive your Roadmap</p>
            <p>
              This is where Ina shows you your health roadmap, giving you achievable goals and structure to your health
              foundation.
            </p>
            <p className={'mt-4 color-primary font-semibold'}>Meal Plan Discussion</p>
            <p>
              Here Ina gives you dietary guidelines and appropriate tools to implement your wellness roadmap, along with
              your meal plan*. (*Meal plans are charged for separately).
            </p>
            <p className={'mt-4 color-primary font-semibold'}>Supplements</p>
            <p>
              A discussion and suggestion of supplements that can be used along with your diet and wellness roadmap.
            </p>
          </>
        ),
      },
      {
        title: 'What does a Third / Follow-up Consultation consist of?',
        body: (
          <>
            <p className={'mt-4'}>
              {' '}
              Third / Follow-up consultations are designed for you to stay motivated. A regular, positive feedback loop
              is important to achieve lasting improvements and wellness. During these 30-minute consultations the
              following wil be done:
            </p>
            <p className={'mt-4 ml-8 '}> - Lifestyle monitoring of and modifications to your roadmap.</p>
            <p className={'ml-8'}> - Resetting your foundation based on progress and plan new goals.</p>
            <p className={'ml-8'}> - Refining your eating plan.</p>
            <p className={'ml-8'}> - Additional guidelines and tools you can apply to reach your next goal. </p>
            <p className={'ml-8'}> - Accountability check. </p>
            <p className={'ml-8'}> - Food journal discussion.</p>
          </>
        ),
      },
    ]}
  />
);

const BloodTestsFAQ = (
  <FAQCard
    id="faq-blood-tests"
    title={'Blood Tests'}
    sections={[
      {
        title: 'Can Ina refer me to have a blood test done?',
        body: (
          <>
            <p>
              Ina is registered with Lancet, Pathcare and Ampath. Once Ina has concluded her first assessment she may
              advise you to undergo certain blood tests.
            </p>
          </>
        ),
      },
      {
        title: 'I have already done blood tests. Do I need to Send Ina the results?',
        body: (
          <>
            <p>
              It is a good idea to send any blood test results (done in the past 6 months) to Ina{' '}
              <a href="mailto:info@inanortje.co.za" target="_blank" className={'clickable-word'}>
                {' '}
                HERE
              </a>
              . The more information you provide Ina, the better your wellness experience will be.
            </p>
            <p className={'mt-4'}>
              Any blood test results sent to Ina will be handled with Practitioner-Patient confidentiality and will not
              be disclosed to anyone or any other health care practitioners without your consent.{' '}
            </p>
          </>
        ),
      },
    ]}
  />
);

const GeneTestsFAQ = (
  <FAQCard
    id="faq-gene-tests"
    title={'Gene Tests'}
    sections={[
      {
        title: 'I want to do a gene test. How does it work?',
        body: (
          <div className={'flex flex-col'}>
            <span>
              <strong>1. </strong>Obtain a DNA Collection Kit from Ina Nortje by emailing Ina{' '}
              <a href="mailto:info@inanortje.co.za" target="_blank" className={'clickable-word'}>
                {' '}
                HERE
              </a>{' '}
              or give her a call at +27 84 357 3926. You can have the kit couriered to you (additional courier costs
              will apply) or you can arrange to have the test done at Ina Nortje’s practice.
            </span>
            <span>
              <strong>2. </strong>Take a quick and painless DNA sample from the inside of your cheek.
            </span>
            <span>
              <strong>3. </strong>Clear instructions are included in the DNA Collection Kit or watch the video{' '}
              <a
                href="https://www.youtube.com/watch?v=C8spb601Akg&feature=youtu.be"
                target="_blank"
                className={'clickable-word'}
              >
                {' '}
                HERE
              </a>
              .
            </span>
            <span>
              <strong>4. </strong>Return the sample to your Practitioner or book a courier service by sending an email
              to Ina{' '}
              <a href="mailto:info@inanortje.co.za" target="_blank" className={'clickable-word'}>
                {' '}
                HERE
              </a>
              .
            </span>
            <span>
              <strong>5. </strong>You will be notified via email when the sample arrives at the laboratory and when your
              results are ready.
            </span>
            <span>
              <strong>6. </strong>You can book an in person or virtual 60min feedback session an with Ina to discuss
              your results{' '}
              <a href="https://bookings.robotdesk.co.za/inanortje/ina" target="_blank" className={'clickable-word'}>
                {' '}
                HERE
              </a>
              .
            </span>
          </div>
        ),
      },
      {
        title: 'Which Gene Test is right for me?',
        body: (
          <div className={'flex flex-col'}>
            {makeGeneTestDescription(
              'GeneDiet™',
              'Ever wonder what your obesity risk is, how your body responds to exercise or the type of diet you should be following based on your genetics? This test will help you to gain insights that will help you manage your weight, what diet to follow, what addictive traits you might have, fat breakdown, exercise response and micronutrient needs.'
            )}
            {makeGeneTestDescription(
              'GeneWell™',
              "This particular genetic testing will give you insight into your health and wellness, cancer risk and if any hormone replacement therapy might be needed. General health and wellness insights includes predisposition for type 2 diabetes, heart disease, cholesterol, hypertension, bone health, caffeine sensitivity, salt sensitivity, blood clotting, inflammation and mood disorders. General cancer risk based on your body's detoxification ability and your oestrogen metabolism."
            )}
            {makeGeneTestDescription(
              'GeneRenew™',
              "This tests provides an indication of your skin's sensitivity to the sun, and reagents or creams. This DNA test will give you a clearer idea of how to best care for your skin and guide you in terms of the skin care products that you need or should avoid. You will also get information on collagen synthesis for firmness and elasticity of your skin."
            )}
            {makeGeneTestDescription(
              'GeneSport™',
              'This test is great for athletes on a professional or amateur level. It will help you determine if your athletic abilities are more suited to strength or endurance sports. It will also help you understand your risk of injury and how your body will recover from injury or exercise. This test also includes dietary and training recommendations based on how your body responds to exercise.'
            )}
            {makeGeneTestDescription(
              'GeneCombo™',
              'This test is by far the most popular choice. This test is a combination of the above four tests (GeneRenew™, GeneSport™, GeneDiet™ and GeneWell™) giving all the insights gained from testing 60 genes.'
            )}
            {makeGeneTestDescription(
              'GeneRX™',
              'This test gives you insights into Pharmacogenetics. It will help you identify if you are the right patient, using the right drug, at the right dose, at the right time with the right route.'
            )}
            {makeGeneTestDescription(
              'SupremeFertility™',
              'Infertility and preconception – DNA testing aimed at couples with unexplained infertility, before treatment with ART (Assisted Reproductive Technology) such as IVF, for preconception to ensure optimal health or with a personal or family history of infertility and/or miscarriages.'
            )}
            {makeGeneTestDescription(
              'GeneImmune™',
              'GeneImmune test relevant immunity and oxidative stress genes that may play a role in COVID-19. The test can be done as a stand-alone test or as a combination with a Genecombo test.',
              false
            )}
          </div>
        ),
      },
      {
        title: 'What do Gene Tests cost?',
        body: (
          <div>
            <div
              className={'grid grid-cols-2'}
              style={{
                width: '360px',
              }}
            >
              <span>GeneDiet™</span>
              <span>R 4 795.00</span>
              <span>GeneWell™</span>
              <span>R 4 795.00</span>
              <span>GeneRenew™</span>
              <span>R 4 500.00</span>
              <span>GeneSport™</span>
              <span>R 4 795.00</span>
              <span>GeneCombo™</span>
              <span>R 4 990.00</span>
              <span>GeneRX™</span>
              <span>R 4 100.00</span>
              <span>SupremeFertility™</span>
              <span>R 3 995.00</span>
              <span>GeneImmune™</span>
              <span>R 2 895.00</span>
              <span>Feedback Session</span>
              <span>R &nbsp; &nbsp;900.00</span>
            </div>
            <div
              style={{
                fontStyle: 'italic',
                fontWeight: 600,
                marginTop: '2px',
              }}
            >
              Note: Courier costs are not included
            </div>
          </div>
        ),
      },
      {
        title: 'How long before I get my Gene Test results?',
        body: (
          <>
            <p>The turnaround time for genetic and food sensitivity tests are 2-3 weeks</p>
          </>
        ),
      },
      {
        title: 'Can I eat or drink anything before a Gene Test?',
        body: (
          <>
            <p>
              Before doing a Genetic test refrain from the following two hours before the test: Eating foods 🍏,
              drinking liquids 🧃, (including water), brushing your teeth, smoking or chewing gum 🍬 - before doing a
              Genetic test.
            </p>
          </>
        ),
      },
    ]}
  />
);

export const AllFAQCards = [GeneTestsFAQ, BloodTestsFAQ, BookingsFAQ, ServicesFAQ, PaymentsFAQ];
