import React, { FC } from 'react';
import './Services.scss';

import { graphql, useStaticQuery } from 'gatsby';

import { AllFAQCards } from '../FAQCards';
import { Page } from 'components/Page/Page';
import { createBemHelper } from 'utils/BEM';
import { AreaOfExpertiseCard } from '../../components/AreaOfExpertiseCard/AreaOfExpertiseCard';
import Media from 'react-media';
import { Button } from 'components/Button/Button';

const BEM = createBemHelper('services');

const NO_OP = () => {};

export interface ServiceProps {
  onShowBooking: () => void;
}

export const Services: FC<ServiceProps> = props => {
  const data = useStaticQuery(graphql`
    query ServicePageQuery {
      heroImage: file(relativePath: { eq: "stock-images/bowl-1.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      step1Image: file(relativePath: { eq: "stock-images/circle-orange.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      step2Image: file(relativePath: { eq: "stock-images/circle-lemon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      step3Image: file(relativePath: { eq: "stock-images/circle-lime.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vitalityIcon: file(relativePath: { eq: "icons/VitalityIcon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      geneTestIcon: file(relativePath: { eq: "icons/GeneTestIcon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hunterIcon: file(relativePath: { eq: "icons/HunterIcon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      couplesIcon: file(relativePath: { eq: "icons/CouplesIcon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bodyCompIcon: file(relativePath: { eq: "icons/BodyCompIcon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      eatingPlanIcon: file(relativePath: { eq: "icons/EatingPlanIcon.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      diseaseManagementImage: file(relativePath: { eq: "stock-images/berries-1.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      dnaGeneTestingImage: file(relativePath: { eq: "stock-images/cucumber-1.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gastroImage: file(relativePath: { eq: "stock-images/eggs-1.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lifestyleNutritionImage: file(relativePath: { eq: "stock-images/greens-2.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      foodSensitivitiesImage: file(relativePath: { eq: "stock-images/eggs-2.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Page title={'Services'} onShowBooking={props.onShowBooking}>
      <div className={`${BEM()} flex flex-col items-center w-full`}>
        {/* Hero */}
        <div className={'grid grid-cols-2 tw:grid-cols-1'}>
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col items-left justify-center">
              <div
                className={'font-roboto text-left mb-4 tw:text-center'}
                style={{
                  fontWeight: 500,
                  fontSize: '58px',
                  lineHeight: '68px',
                  color: '#1D2251',
                }}
              >
                Start out right!
              </div>
              <div
                className="tw:text-center"
                style={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                  maxWidth: '358px',
                  color: '#1D2251',
                }}
              >
                Your health journey with Ina Nortje will be fun, easy and leave you feeling amazing!
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img src={data.heroImage.childImageSharp.fixed.src} />
          </div>
        </div>
        <div className={'flex items-center ts:flex-col ts:justify-center'}>
          <div
            className={'cursor-pointer'}
            onClick={scrollToId('services')}
            style={{
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.6px',
              color: 'var(--color-clickable-link)',
            }}
          >
            Services
          </div>
          {dot}
          <div
            className={'ml-4 ts:mt-4 ts:ml-0 cursor-pointer'}
            onClick={scrollToId('specialities')}
            style={{
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.6px',
              color: 'var(--color-clickable-link)',
            }}
          >
            Specialties
          </div>
          {dot}
          <div
            className={'ml-4 ts:mt-4 ts:ml-0 cursor-pointer'}
            onClick={scrollToId('faqs')}
            style={{
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.6px',
              color: 'var(--color-clickable-link)',
            }}
          >
            FAQs
          </div>
        </div>
        {SectionHeading('Services', 'services')}
        <div
          className="grid tw:grid-cols-1 gap-y-4 gap-x-12 grid-rows-5-auto tw:grid-rows-15-auto justify-items-center"
          style={{
            gridAutoFlow: 'column',
            maxWidth: '1000px',
          }}
        >
          {ServiceStep(
            1,

            <>
              First
              <br />
              Consultation
            </>,
            ' This 60-minute consultation will begin with detailed dietary, anthropometric, clinical, medical, and lifestyle assessments. This helps Ina build an effective roadmap to give you the right tools and guidance to help you reach your health goals.',
            data.step1Image.childImageSharp.fixed.src
          )}
          {ServiceStep(
            2,
            <>
              Second
              <br />
              Consultation
            </>,
            'This is a 60-minute consultation where Ina will present you with your detailed and easy-to-follow wellness roadmap. This plan will contain key, achievable objectives - along all the tools you will need to make your health goal a reality.',
            data.step2Image.childImageSharp.fixed.src
          )}
          {ServiceStep(
            3,
            <>
              Third
              <br />
              Consultation
            </>,
            'This is a 30-minute check-in to measure your progress toward your health goal. These sessions assess your progress and refine your wellness roadmap. Regular check-ins keep you motivated and address any new challenges on your health journey. ',
            data.step3Image.childImageSharp.fixed.src
          )}
        </div>
        {SectionSubheading('Additional Services')}
        <div
          className="grid grid-rows-6-auto tw:grid-rows-9-auto ts:grid-rows-18-auto tw:grid-cols-2 ts:grid-cols-1 gap-y-4 gap-x-12 justify-items-center"
          style={{
            gridAutoFlow: 'column',
            maxWidth: '1000px',
          }}
        >
          {AdditionalServiceBlock(
            'Body Composition',
            <>
              Do you want a leaner, more toned body? Do you want to reduce your risk of heart disease or diabetes? This
              30-min scan can show you how to achieve all of this and more. This{' '}
              <a href="https://www.youtube.com/watch?v=B6jIUOi8puk" target="_blank" className={'clickable-word'}>
                {' '}
                VIDEO
              </a>{' '}
              can give you more info.
            </>,
            data.bodyCompIcon.childImageSharp.fixed.src
          )}
          {AdditionalServiceBlock(
            'Hunter Virtual 3D Scan',
            <>
              The Hunter 3D scan uses biofeedback to examine the body's systems and highlights potential problems. It
              can be booked with a consultation or as a standalone appointment. Click{' '}
              <span className={'clickable-word'} onClick={scrollToId('faq-services')}>
                {' '}
                HERE
              </span>{' '}
              to find out more.
            </>,
            data.hunterIcon.childImageSharp.fixed.src
          )}
          {AdditionalServiceBlock(
            'DNA & Gene Testing',
            <>
              Our genes hold the key to our health. A simple gene test can benefit you for the rest of your life.
              Contact Ina and unlock the tools you need to prevent disease and live your best life. Click{' '}
              <span className={'clickable-word'} onClick={scrollToId('faq-gene-tests')}>
                HERE
              </span>{' '}
              to read more.
            </>,
            data.geneTestIcon.childImageSharp.fixed.src
          )}
          {AdditionalServiceBlock(
            'Vitality Nutrition Assessments',
            <>
              Do you need a Discovery Nutritional Assessment? Book your 30-minute assessment with Ina and earn up to
              1000 Vitality points. Only available to Discovery Vitality members.{' '}
            </>,
            data.vitalityIcon.childImageSharp.fixed.src
          )}
          {AdditionalServiceBlock(
            'Meal Plan',
            <>
              Knowing what food to buy and how to prepare it is essential in achieving your health goal. Ina can build
              you a custom eating plan, taking the worry out of meals. Meal plans are charged for separately at R490.00
              pp.
            </>,
            data.eatingPlanIcon.childImageSharp.fixed.src
          )}
          {AdditionalServiceBlock(
            'Couples Consultations',
            <>
              Embarking on a journey to achieve a better, healthier life has its challenges. A partner-in-crime can help
              you overcome this. You can book a Couples Consultation with your spouse, partner or friend.
            </>,
            data.couplesIcon.childImageSharp.fixed.src
          )}
        </div>
        <Button size={'large'} onClick={props.onShowBooking}>
          BOOK NOW
        </Button>
        {SectionHeading('Specialities', 'specialities')}
        {buildAreaOfExpertiseCard(
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Heading('Disease Management')}
            <p className={'text-sm'}>
              A major (and often neglected) part of mastering well-controlled chronic illnesses – such as hypertension,
              diabetes etc. - is DIET. Learn how to beat the daily pills with a healthy lifestyle. Ina specialises in
              the following:
            </p>
            {AreaOfExpertiseUtil.Subheading('Hormonal Disorders')}
            <div className={'flex flex-col text-sm'}>
              <span> - Hyper & Hypothyroidism </span>
              <span> - Mitochondrial disease</span>
              <span> - Polycystic Ovarian Syndrome</span>
            </div>
            {AreaOfExpertiseUtil.Subheading('Inflammatory Conditions')}
            <div className={'flex flex-col text-sm'}>
              <span>- Arthritis </span>
              <span>- Gout</span>
              <span>- Inflammatory Bowel Disease</span>
              <span>- Fibromyalgia</span>
            </div>
          </div>,
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Subheading('Chronic Conditions')}
            <div className={'flex flex-col text-sm'}>
              <span> - Blood pressure (Hypertension) </span>
              <span> - Cancer Nutritional Prevention</span>
              <span> - Cancer therapy during treatment</span>
              <span> - Cholesterol</span>
              <span> - Diabeters (Type 1 & 2)</span>
              <span> - Fatty Liver Disease (NAFLD)</span>
              <span> - Overweight / Obesity</span>
            </div>
            {AreaOfExpertiseUtil.Subheading('Nutritional Deficiencies')}
            <div className={'flex flex-col text-sm'}>
              <span> - Vitamin & Mineral deficiencies</span>
              <span> - Hemochromatosis (Too much iron)</span>
              <span> - Herbal remedies</span>
              <span> - Iron deficiency and anaemia</span>
              <span> - Vit D deficiency</span>
            </div>
            {AreaOfExpertiseUtil.Subheading('Mental Health Conditions')}
            <div className={'flex flex-col text-sm'}>
              <span> - Anxiety disorders, </span>
              <span> - Bipolar disorders</span>
              <span> - Mood disorders </span>
            </div>
          </div>,
          data.diseaseManagementImage.childImageSharp.fixed.src,
          'right'
        )}
        {buildAreaOfExpertiseCard(
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Heading('DNA & Gene Testing')}
            <p className={'text-sm'}>
              Lifestyle Genetic tests are intended to provide information about your responsiveness to particular
              nutrients or diets and how this affects your metabolism, health status, aging, fertility, physical
              performance and risk for disease.
            </p>
            <p className={'text-sm'}>
              90% of all genes’ effects are lifestyle related. A simple gene test can show you how a certain lifestyle
              adjustments can support your genes to give you a positive outcome.
            </p>
            <p className={'text-sm'}>
              It's never too late to do a gene test - making targeted lifestyle changes so much easier.
            </p>
            <span className={'clickable-word'} onClick={scrollToId('faq-gene-tests')}>
              Click here to find out more {'>>'}
            </span>
          </div>,
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Subheading('Which genes can be tested?')}
            <div className={'flex flex-col text-sm'}>
              <span> - Lifestyle Wellness Genes</span>
              <span> - Sport Genes</span>
              <span> - Diet Genes</span>
              <span> - Anti-Aging Genes</span>
              <span> - Immunity Genes</span>
              <span> - Fertility Genes</span>
              <span> - Retinal Homeobox Genes</span>
            </div>
            {AreaOfExpertiseUtil.Subheading('Gene Test Products')}
            <div className={'flex flex-col text-sm'}>
              <span> - GeneDiet™</span>
              <span> - GeneWell™</span>
              <span> - GeneRenew™</span>
              <span> - GeneSport™</span>
              <span> - GeneCombo™</span>
              <span> - GeneRX™</span>
              <span> - SupremeFertility™</span>
              <span> - GeneImmune™</span>
            </div>
          </div>,
          data.dnaGeneTestingImage.childImageSharp.fixed.src,
          'left'
        )}
        {buildAreaOfExpertiseCard(
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Heading('Gastro-Intestinal')}
            <p className={'text-sm'}>
              Do you often suffer from abdominal bloating? Nausea? Diarrhoea? Stomach cramps? You could be suffering
              from Irritable Bowel Syndrome or other gastro-intestinal problems.
            </p>

            <p className={'text-sm'}>
              35% of the world population suffer from some sort of Gastro-Intestinal condition due to the food they eat.
            </p>

            <p className={'text-sm'}>
              Let Ina guide you on your journey to a healthy gut and improve your quality of life through proper
              education and fun adaptations.
            </p>
          </div>,
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Subheading('Gastro-Intestinal Conditions')}
            <div className={'flex flex-col text-sm'}>
              <span> - Celiac disease</span>
              <span> - Constipation</span>
              <span> - Crohn’s disease</span>
              <span> - Diarrhoea</span>
              <span> - Diverticular disease</span>
              <span> - Gastritis</span>
              <span> - Heartburn (Dyspepsia)</span>
              <span> - Irritable Bowel Syndrome (IBS)</span>
              <span> - Inflammatory Bowel Disease</span>
              <span> - Acid Reflux</span>
              <span> - Persistent Heartburn</span>
              <span> - Stomach ulcers</span>
              <span> - Ulcerative Colitis</span>
            </div>
          </div>,
          data.gastroImage.childImageSharp.fixed.src,
          'right'
        )}
        {buildAreaOfExpertiseCard(
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Heading('Lifestyle Nutrition')}
            <p className={'text-sm'}>
              Losing weight doesn’t have to be the tiring struggle we all know it to be. With Ina’s help, you could
              learn all you need to know about nutritious food, portion control and flexible dieting. Ina specialises
              in:
            </p>

            <div className={'flex flex-col text-sm'}>
              <span> - Eating Disorders</span>
              <span> - Weight gain</span>
              <span> - Weight loss</span>
              <span> - Sports Nutrition</span>
            </div>

            <p className={'text-sm'}>Yes, you can eat chocolate and lose weight!</p>
          </div>,
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Subheading('Nutritional support through different periods of life')}
            <div className={'flex flex-col text-sm'}>
              <span> - Preconception and Fertility</span>
              <span> - Pregnancy</span>
              <span> - Menopause</span>
              <span> - Osteoporosis</span>
            </div>
            {AreaOfExpertiseUtil.Subheading('Detox Programme')}
            <p className={'text-sm'}>Detoxification is the cornerstone of happy, healthy living.</p>

            <p className={'text-sm'}>
              Through this programme we focus on restoring all phytonutrients, supporting the liver and the body from
              harmful toxins and revitalising energy levels in a fun, healthy and nutritious way.
            </p>
          </div>,
          data.lifestyleNutritionImage.childImageSharp.fixed.src,
          'left'
        )}
        {buildAreaOfExpertiseCard(
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Heading('Food Sensitivity Tests')}
            <p className={'text-sm'}>
              Different people have different reactions to food. One person's food could be another's poison. Food
              intolerances occur when you experience a reaction to specific foods. The symptoms are specific to an
              individual and may include symptoms such as bloating, eczema and fatigue.
            </p>

            <p className={'text-sm'}>
              Increasing evidence shows that food sensitivities are more common and have a significant impact on our
              health. If you have any of the following symptoms, it might be worth doing a food sensitivity test and get
              you back to feeling great.
            </p>
          </div>,
          <div className={`flex flex-col ${BEM('area-of-expertise-column')}`}>
            {AreaOfExpertiseUtil.Subheading('Symptoms associated with food sensitivities')}
            <div className={'flex flex-col text-sm'}>
              <span> - Diarrhoea & Constipation</span>
              <span> - Vomiting</span>
              <span> - Eczema</span>
              <span> - Runny nose</span>
              <span> - Hives (Urticaria)</span>
              <span> - Skin rashes</span>
              <span> - Wheezing</span>
              <span> - Fatigue</span>
              <span> - Gas or Digestive complaints</span>
              <span> - Mood swings</span>
              <span> - Nervousness</span>
              <span> - Migraines </span>
              <span> - Eating disorders</span>
              <span> - Obesity</span>
              <span> - Weight Loss</span>
              <span> - And more</span>
            </div>
          </div>,
          data.foodSensitivitiesImage.childImageSharp.fixed.src,
          'right'
        )}
        <div>
          <div
            className={'subheading font-semibold mt-8 ts:mt-4 mb-8 ts:mb-4'}
            style={{
              color: 'var(--color-accent-dark)',
            }}
          >
            Do you have any questions for Ina? 🙋
          </div>
          {SectionHeading('FAQs', 'faqs')}
          <div className={'subheading mt-8 ts:mt-4'}>
            Here are some Frequently asked questions covering Ina Nortje’s services, specialities and other general
            housekeeping info. Don’t see the answers you are looking for? Feel free to send Ina an email with your
            question.
          </div>
        </div>
        <div className={'w-full flex flex-col items-center ts:ml-bleed-gutter ts:mr-bleed-gutter faq-card-list'}>
          {AllFAQCards}
        </div>
      </div>
    </Page>
  );
};

export const buildAreaOfExpertiseCard = (
  leftColumn: JSX.Element,
  rightColumn: JSX.Element,
  imageSrc: string,
  imagePlacement: 'left' | 'right'
) => {
  return (
    <Media
      queries={{
        small: '(max-width: 640px)',
        medium: '(max-width: 1024px)',
        large: '(min-width: 1025px)',
      }}
    >
      {matches => {
        const view = (function () {
          if (matches.small) {
            return 'small';
          }
          if (matches.medium) {
            return 'medium';
          }
          return 'large';
        })();

        console.log(JSON.stringify(matches), '->', view);

        return (
          <AreaOfExpertiseCard
            view={view}
            columns={[leftColumn, rightColumn]}
            imageSrc={imageSrc}
            imagePlacement={imagePlacement}
          />
        );
      }}
    </Media>
  );
};

const scrollToId = (id: string) => () => {
  const element = document.getElementById(id);
  element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
};

export const AreaOfExpertiseUtil = {
  Heading: (text: string) => {
    return (
      <div
        className={'font-roboto font-bold'}
        style={{
          fontSize: '24px',
          lineHeight: '28px',
          letterSpacing: '0.6px',
          color: '#1E255E',
        }}
      >
        {text}
      </div>
    );
  },
  Subheading: (text: string) => {
    return (
      <div
        className={'font-bold'}
        style={{
          color: 'var(--color-primary)',
          marginBottom: '5px',
        }}
      >
        {text}
      </div>
    );
  },
};

export const AdditionalServiceBlock = (title: string, text: JSX.Element, imageSrc: string) => {
  return (
    <>
      <div className={'flex items-center'}>
        <img
          src={imageSrc}
          style={{
            maxWidth: '108px',
          }}
        />
      </div>
      <div
        className={'font-roboto text-center'}
        style={{
          fontSize: '24px',
          lineHeight: '28px',
          fontWeight: 500,
          color: '#1D2251',
          maxWidth: '195px',
        }}
      >
        {title}
      </div>
      <div
        className={'text-center'}
        style={{
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '0.4px',
          color: '#1D2251',
          maxWidth: '195px',
        }}
      >
        {text}
      </div>
    </>
  );
};

export const ServiceStep = (n: number, title: JSX.Element, description: string, imageSrc: string) => {
  return (
    <>
      <div
        className="text-center font-roboto"
        style={{
          fontSize: '78px',
          fontWeight: 400,
          color: '#1D2251',
        }}
      >
        {['-', n, '-'].join(' ')}
      </div>
      <div
        className="text-center font-roboto"
        style={{
          fontSize: '38px',
          lineHeight: '46px',
          color: '#1D2251',
        }}
      >
        {title}
      </div>
      <div
        className="text-center"
        style={{
          lineHeight: '24px',
          letterSpacing: '0.4px',
          color: '#1D2251',
          maxWidth: '300px',
        }}
      >
        {description}
      </div>
      <div
        className={'mt-4 text-center clickable-word'}
        onClick={scrollToId('faq-bookings')}
        style={{
          color: 'var(--color-primary)',
          fontWeight: 'bold',
        }}
      >
        More Info {'>>'}
      </div>
      <div className="flex items-end justify-center mt-24 tw:mt-12">
        <img src={imageSrc} />
      </div>
    </>
  );
};

export const SectionHeading = (text: string, id: string) => {
  return (
    <div
      id={id}
      className={'text-center font-roboto'}
      style={{
        color: '#1D2251',
        fontSize: '58px',
        fontWeight: 500,
        lineHeight: '68px',
      }}
    >
      {text}
    </div>
  );
};

export const SectionSubheading = (text: string, align: string = 'center') => {
  return (
    <div
      className={`text-${align} font-roboto`}
      style={{
        color: '#1D2251',
        fontSize: '38px',
        fontWeight: 400,
        lineHeight: '46px',
      }}
    >
      {text}
    </div>
  );
};

function repeat<T>(item: T, n: number): T[] {
  const result: T[] = [];
  for (let i = 0; i < n; i++) {
    result.push(item);
  }
  return result;
}

const dot = (
  <div
    className={'ml-4 ts:hidden'}
    style={{
      backgroundColor: '#1F255E',
      borderRadius: '50%',
      height: '10px',
      width: '10px',
    }}
  />
);

export default Services;
