import React, { FC } from 'react';

export interface AreaOfExpertiseCardsProps {
  columns: [JSX.Element, JSX.Element];
  imageSrc: string;
  imagePlacement: 'left' | 'right';
  view: 'large' | 'medium' | 'small';
}

export const AreaOfExpertiseCard: FC<AreaOfExpertiseCardsProps> = props => {
  const { columns, imageSrc, imagePlacement, view } = props;
  if (view === 'small') {
    return (
      <div
        className={`flex flex-col p-8`}
        style={{
          boxShadow: '0 2px 6px 2px rgba(190, 190, 190, 0.49)',
          width: `ch(65)`,
        }}
      >
        {columns[0]}
        <br />
        {columns[1]}
      </div>
    );
  }

  if (view === 'medium') {
    return (
      <div
        className={'grid grid-cols-2-1fr p-8 gap-x-12'}
        style={{
          boxShadow: '0 2px 6px 2px rgba(190, 190, 190, 0.49)',
        }}
      >
        <div>
          <div className={'flex items-center'}></div>
          {columns[0]}
        </div>
        {columns[1]}
      </div>
    );
  }

  // How high the image's marginTop is being offset.
  const OFFSET = '48px';

  const img = (
    <div>
      <img
        style={{
          height: '407px',
          marginBottom: '0px',
          marginTop: `-${OFFSET}`,
        }}
        src={imageSrc}
      />
    </div>
  );

  const gridClasses = `grid grid-cols-area-of-expertise-card-full-image-${imagePlacement} tw:grid-cols-1-1fr gap-x-12`;

  return (
    <div
      className={`flex justify-center`}
      style={{
        marginTop: OFFSET,
        width: '900px',
      }}
    >
      <div
        className={`${gridClasses} p-area-of-expertise-image-${imagePlacement} justify-items-center tw:justify-items-start rounded-sm`}
        style={{
          boxShadow: '0 2px 6px 2px rgba(190, 190, 190, 0.49)',
          minWidth: '1000px',
          padding: imagePlacement === 'right' ? '24px 8px 24px 38px' : '24px 38px 24px 8px',
        }}
      >
        {imagePlacement === 'left' && img}
        {columns[0]}
        {columns[1]}
        {imagePlacement === 'right' && img}
      </div>
    </div>
  );
};
